.logo{
    position: relative;
    width: 220px;
    height: 220px;
    top: -20px;
}
.information-header{
    margin-top: 24px;
    display: flex;
}
.left-wrapper{
    text-align: right;
    padding-right: 70px;

    .background-circle{
        background-color: #e4e4e4;
        width: 100%;
        max-width: 507px;
        height: 507px;
        border-radius: 50%;
        position: relative;

        .product1{
            position: absolute;
            left: 15%;
            bottom: 10%;
            width: 41%;
            height: 110%;
        }
        .product2{
            position: absolute;
            bottom: 6%;
            right: 10%;
            width: 41%;
            height: 110%;
            transform: rotate(16.67deg);
        }
    }
}
.right-wrapper{
    text-align: left;
    .title{
        color: var(--orange);
        font-family: 'PT Serif';
        font-weight: 400;
        font-size: 20px;
    }
    .content-header{
        font-size: 48px;
        font-family: 'PT Serif';
        line-height: 1.2;
        padding-top: 10px;
    }
    .bottom{
        padding-top: 50px;
        width: 70%;
        .bottom-button{
            background-color: var(--green);
            color: var(--white);
            border-radius: 79px;
            font-weight: 500;
            font-size: 18px;
            padding: 15px 35px;
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .rate{
            gap: 49px;
            padding-top: 20px;
            .icon{
                color: var(--orange);
                width: 29px;
                height: 29px;
                margin-left: 10px;
            }
            h2{
                margin: 0;
                font-weight: 700;
                font-size: 32px;
                padding-left: 10px;
            }
            p{
                font-weight: 400;
                font-size: 18px;
                color: var(--grey);
            }
        }
    }
}
@media (max-width: 1440px) {
    .information-header{
        margin-top: 0px;
    }   
    .right-wrapper{
        .content-header{
            font-size: 42px;
        }
    }
}
@media (max-width: 1024px) {
    .information-header{
        margin-top: 0;
    }
    .left-wrapper{
        padding-right: 50px;
        
    }
    .right-wrapper{
        .bottom{
            width: 100%;
        }
        .content-header{
            font-size: 36px;
        }
    }
}
@media (max-width: 768px) {
    .information-header{
        margin-top: 0;
    } 
    .left-wrapper{
        padding-right: 50px;
        .background-circle{
            height: 400px;
            
        }
    }
    .right-wrapper{
        .title{
            font-size: 15px;
        }
        .content-header{
            font-size: 30px;
        }
        .bottom{
            width: 100%;
            padding-top: 35px;
            .bottom-button{
                padding: 10px 25px;
                font-size: 16px;
            }
            .rate{
                gap: 30px;
                display: inline-block !important;
                .icon{
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                }
                h2{
                    font-size: 25px;
                    padding-left: 6px;
                }
                p{
                    font-size: 14px;
                }
            }
        }
    }
}
@media (max-width: 481px) {
    .information-header{
        display: block;
        margin-top: 0;
    } 
    .left-wrapper{
        width: 100%;
        padding: 0;
        .background-circle{
            height: 375px;
        }
    }
    .right-wrapper{
        width: 100%;
        .title{
            font-size: 13px;
        }
        .content-header{
            font-size: 20px;
        }
        .bottom{
            width: 100%;
            padding-top: 25px;
            .button-wrapper{
                display: flex;
                justify-content: center;
            }
            .bottom-button{
                padding: 8px 18px;
                font-size: 13px;
            }
            .rate{
                display: flex !important;
                .icon{
                    width: 15px;
                    height: 15px;
                    margin-left: 0px;
                }
                h2{
                    font-size: 15px;
                    padding-left: 6px;
                }
                p{
                    font-size: 12px;
                }
            }
        }
    }
}