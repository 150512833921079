.choose-me-1 {
    .main-text {
        text-align: center;
        width: 35%;
        margin: 0 auto;

        h2 {
            font-family: 'PT Serif';
            font-weight: 400;
            font-size: 48px;
        }

        img {
            position: relative;
            top: -30px;
        }

        h5 {
            color: var(--grey);
            font-size: 18px;
            line-height: 1.2;
            position: relative;
            top: -30px;
            margin: 25px;
        }
    }

    .block-wrapper {
        width: 24%;
        height: 275px;
        padding: 32px 32px 32px 26px;
        background-color: #F0EFEA;
        margin-top: 25px;
        border-radius: 16px;
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        }

        .circle {
            background-color: #CDD5CA;
            border-radius: 50%;
            width: 58px;
            height: 58px;
            text-align: center;
        }

        h3 {
            font-weight: 700;
            font-size: 22px;
            padding: 20px 0 15px;
            margin: 0;
        }

        p {
            font-size: 18px;
            color: var(--grey);
        }
    }
    @media (max-width: 1600px) {
        .block-wrapper{
            p{
                font-size: 16px;
            }
        }
    }
    @media (max-width: 1400px) {
        .block-wrapper{
            p{
                font-size: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .main-text {
            width: 50%;

            h2 {
                font-size: 40px;
            }

            h5 {
                font-size: 16px;
            }
        }

        .block-wrapper {
            width: 23%;
            padding: 20px 30px 20px 20px;

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .block-wrapper {
            width: 45%;
        }
    }

    @media (max-width: 768px) {
        .main-text {
            width: 70%;

            h2 {
                font-size: 32px;
            }

            h5 {
                font-size: 14px;
            }
        }

        .block-wrapper {
            width: 250px;
            padding: 15px 30px 15px 15px;
            height: 250px;

            h3 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }

            .circle {
                width: 50px;
                height: 50px;
            }
        }
    }

    @media (max-width: 481px) {
        .main-text {
            width: 90%;

            h2 {
                font-size: 24px;
            }

            h5 {
                margin: 10px;
                font-size: 12px;
            }
        }

        .block-wrapper {
            width: 100%;
            padding: 10px 20px 10px 10px;
            height: 180px;

            h3 {
                font-size: 16px;
            }

            p {
                font-size: 12px;
            }

            .circle {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}

.choose-me-2 {
    margin-top: 100px;
    background-color: #67685C;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 90px;
    .big-img{
        width: 66%;
    }
    .box-wrapper {
        padding: 0 55px 0 35px;

        h3 {
            font-family: 'PT Serif';
            color: var(--white);
            font-weight: 700;
            font-size: 32px;
            padding-top: 40px;
        }

        p {
            font-size: 20px;
            line-height: 29px;
            color: var(--white);
            text-align: left;
            margin-top: 24px;
        }

        .img-wrapper {
            display: flex;
            justify-content: space-between;
            position: relative;
            bottom: -60px;
            img {
                max-width: 100%;
                width: 48%;
                height: auto;
            }
            .prev, .next {
                display: block;
                cursor: pointer;
                position: absolute;
                top: 50%;
                width: auto;
                padding: 12px;
                margin-top: -22px;
                color: white;
                font-weight: bold;
                font-size: 10px;
                transition: 0.6s ease;
                border-radius: 0 3px 3px 0;
                user-select: none;
                background-color: rgba(0,0,0,0.5);
                display: none;
            }

            .next {
                right: 0;
                border-radius: 3px 0 0 3px;
            }

            .prev:hover, .next:hover {
                background-color: rgba(0,0,0,0.8);
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    @media (max-width: 1440px) {
        .big-img{
            width: 60%;
        }
        .box-wrapper {
            padding: 0 40px 0 20px;

            h3 {
                font-size: 28px;
            }

            p {
                font-size: 18px;
            }
            .img-wrapper {
                bottom: -65px;
                img{
                    width: 48%;
                }
            }
        }
    }
    @media(max-width: 1024px) {
        .big-img{
            width: 55%;
        }
        .box-wrapper {
            padding: 0 30px 0 15px;

            h3 {
                font-size: 26px;
            }

            p {
                font-size: 16px;
            }
            .img-wrapper {
                bottom: -75px;
                img{
                    width: 48%;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .choose-me-2-wrapper{
            display: block !important;
        }
        flex-direction: column;

        .big-img {
            width: 100%
        }

        .box-wrapper {
            padding: 20px;
            text-align: center;

            h3 {
                font-size: 28px;
            }

            p {
                font-size: 16px;
            }

            .img-wrapper {
                img{
                    width: 80%;
                    margin: auto;
                }
                .slide {
                    display: none;
                }
    
                .slide.active {
                    display: block;
                }
                .prev, .next {
                    display: block;
                }
            }
        }
    }

    @media (max-width: 481px) {
        margin-top: 50px;
        .box-wrapper {
            padding: 10px;

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
            }

            .img-wrapper {
                bottom: -50px;
            }
        }

        img {
            width: 100%;
        }
    }
}
/* Add existing styles here */

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    background-color: rgba(0, 0, 0, -1.1);
}

.modal img {
    width: 50%;
    height: auto;
    margin: auto;
    background-color: rgba(0, 0, 0, -1.1);
}

.close {
    position: absolute;
    top: 10px;
    right: 25px;
    color: #fff;
    font-size: 35px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* Show the modal when it has the 'show' class */
.modal.show {
    display: flex;
}
@media(max-width: 768px){
    .modal-content{
        img{
            width: 75%;
        }
    }
}