.for-you {
    margin-top: 70px;
    background-image: url('../../../assest/foryoubackground.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 1093px;
    color: var(--white);
    display: flex;
    align-items: center;

    .left-wrapper {
        width: 40%;
        text-align: left;
        margin-left: 10%;

        h2 {
            font-family: 'PT Serif';
            font-size: 40px;
            font-weight: 700;
        }

        p {
            margin-top: 16px;
            font-size: 20px;
            line-height: 29px;
        }
    }
}
.for-you-bot-wrapper{
    margin: 60px 0;
    position: relative;
    .for-you-2-branch{
        position: absolute;
        width: 30%;
        top: -13%;
        left: 0%;
    }
    .for-you-2-branch-2{
        position: absolute;
        width: 46%;
        bottom: -35%;
        right: 0;
    }
    .for-you-2-background{
        position: relative;
        height: auto;
        width: 100%;
        z-index: 1;
    }
}
@media (max-width: 1500px) {
    .for-you {
        height: 1000px;
        .left-wrapper{
            p{
                font-size: 17px;
            }
        }
    }
}
@media (max-width: 1200px) {
    .for-you {
        height: 850px;
    }
}
/* Media query for screens smaller than 1024px */
@media (max-width: 1024px) {
    .for-you {
        background-size: cover;
        height: 700px;
        .left-wrapper {
            padding: 20px;
    
            h2 {
                font-size: 30px;
            }
    
            p {
                font-size: 18px;
            }
        }
    }

    
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
    .for-you {
        background-size: cover;
        height: 600px;

        .left-wrapper {
            margin-left: 0;
            text-align: left;
            width: 50%;
            h2 {
                font-size: 24px;
            }
    
            p {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
@media (max-width: 481px) {
    .for-you {
        height: 350px;
        .left-wrapper{
            p{
                font-size: 11px;
                line-height: 15px;
            }
            
        }
    }
}