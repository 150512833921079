.recipe{
    margin-top: 120px;
    color: var(--green);
    position: relative;
    .recipe-branch{
        position: absolute;
        bottom: -17%;
        left: -9%;
        width: 26%;
    }
    .recipe-branch-2{
        position: absolute;
        width: 35%;
        bottom: -12%;
        right: 0;
    }
    .left-wrapper{
        padding: 0;
        img{
            position: relative;
            width: 73%;
        }
    }
    .right-wrapper{
        margin: auto 0;
        h4{
            font-weight: 600;
            font-size: 24px;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #929292;
            b{
                color: #000;
            }
        }
        .icon{
            color: #000;
            width: 12px;
            height: 18px;
        }
        .item-wrapper{
            margin-top: 2.5rem;
            cursor: pointer;
        }
        .p-content {
            overflow: hidden;
            transition: height 0.3s ease-out, opacity 0.3s ease-out;
            height: 0;
            opacity: 0;
        }

        .p-content.expanded {
            height: auto; /* Auto height for expanded state */
            opacity: 1;
        }
    }
}
@media(max-width: 1440px){
    .recipe{
        .recipe-branch{
            bottom: -17%;
            left: 0%;
        }
    }
}
@media(max-width: 1200px){
    .recipe-branch-2{
        bottom: -14%;
    }
}
@media (max-width: 1024px){
    .recipe{
        margin-top: 80px;
        .recipe-branch{
            bottom: -21%;
        }
        .recipe-branch-2{
            bottom: -16%;
        }
        .right-wrapper{
            .item-wrapper{
                h4{
                    font-size: 22px;
                }
            }
            p{
                font-size: 15px;
            }
        }
    }
}
@media (max-width: 768px){
    .recipe{
        margin-top: 60px;
        .recipe-branch{
            bottom: -25%;
        }
        .recipe-branch-2{
            bottom: -18%;
        }
        .right-wrapper{
            .item-wrapper{
                h4{
                    font-size: 16px;
                }
            }
            p{
                font-size: 11px;
            }
        }
    }
}
@media (max-width: 481px){
    .recipe{
        margin-top: 40px;
        .recipe-branch, .recipe-branch-2{
            display: none;
        }
        .receipe-wrapper{
            flex-direction: column;
        }
        .left-wrapper{
            img{
                width: 100%;
            }
            
        }
        .right-wrapper{
            .p-content{
                p{
                    margin-bottom: 0 !important;
                }
            }
            .item-wrapper{
                margin-top: 1.5rem;
                h4{
                    font-size: 15px;
                }
            }
            p{
                width: 100%;
                font-size: 11px;
            }
        }
    }
}