.authenticate{
    position: relative;
    background-color: #67685C;
    color: var(--white);
    .authenticate-text{
        text-align: center;
        width: 35%;
        margin: 0 auto;
        h2{
            font-family: 'PT Serif';
            font-weight: 400;     
            font-size: 48px;
        }
        img{
            position: relative;
            top: -15px;
        }
        h5{
            font-size: 18px;
            line-height: 1.2;
            position: relative;
            top: -30px; 
            margin: 25px;
        }
    }
    .authenticate-list {
        display: flex;
        flex-wrap: wrap; // Maintain existing layout for larger screens
        overflow: hidden; // Hide overflowing slides
        position: relative;
        justify-content: space-between;
    }
    .block-wrapper{
        max-width: 379px;
        width: 24%;
        height: 381px;
        padding: 32px 32px 32px 26px;
        background-color: #FFFFFF1A;
        margin-top: 25px;
        
        img{
            width: 48px;
            height: 48px;
            border-radius: 50%;
        }
        .infor-wrapper{
            margin: auto 0;
            padding-left: 20px;
        }
        h3{
            font-weight: 600;
            font-size: 16px;
            margin: 0;
        }
        h5{
            font-weight: 400;
            font-size: 14px;
            margin: 0;
        }
        p{
            margin-top: 30px;
            font-weight: 400;
            font-size: 18px;
        }
    }
    .nextbtn, .prevbtn {
        cursor: pointer;
        position: absolute;
        top: 70%;
        width: 72px;
        height: 72px;
        padding: 0;
        background-color: white;
        border-radius: 50%;
        color: black;
        font-weight: bold;
        font-size: 10px;
        transition: 0.6s ease;
        user-select: none;
    }
    .nextbtn{
        right: 1%;
        box-shadow: 0px 12px 8px black;
    }
    .prevbtn{
        left: 1%;
        box-shadow: 6px 10px 8px black;
    }
}
.authenticate-bottom{
    img{
        height: auto;
        width: 100%;
    }
}
@media (max-width: 1440px){
    .authenticate{
        .authenticate-text{
            width: 40%;
        }
        .authenticate-list{
            .block-wrapper{
                p{
                    font-size: 16px;
                }
            }
        }
        .nextbtn, .prevbtn{
            width: 60px;
            height: 60px;
            img{
                width: 30px;
            }
        }
    }
}
@media (max-width: 1200px){
    .authenticate{
        .authenticate-list{
            .block-wrapper{
                p{
                    font-size: 15px;
                }
            }
        }
    }
}
@media (max-width: 1024px){
    .authenticate{
        .authenticate-text{
            width: 50%;
        }
        .authenticate-list{
            width: 90%;
            .block-wrapper{
                height: auto;
                flex: 1 0 100%;
                min-width: 100%;
                display: none;
            }
            .block-wrapper.active {
                display: block; // Only display the active slide on small screens
            }
            .slide-controls {
                display: flex; // Show controls on smaller screens
                position: absolute;
                top: 50%;
                width: 100%;
                justify-content: space-between;
                transform: translateY(-50%);
                z-index: 1; // Ensure buttons are above content
            }
        }
        .nextbtn, .prevbtn{
            width: 40px;
            height: 40px;
            img{
                width: 20px;
            }
        }
    }
}
@media (max-width: 768px){
    .authenticate{
        .authenticate-text{
            width: 75%;
            h2{
                font-size: 40px;
            }
            h5{
                font-size: 15px;
            }
        }
        .authenticate-list{
            justify-content: center !important;
            .block-wrapper{
                margin-top: 0;
                margin-bottom: 15px;
            }
        }
    }
}
@media (max-width: 425px){
    .authenticate{
        .authenticate-text{
            width: 100%;
            h2{
                font-size: 35px;
            }
            h5{
                font-size: 13px;
            }
        }
        .authenticate-list{
            justify-content: center !important;
            .block-wrapper{
                margin-top: 0;
                margin-bottom: 15px;
            }
        }
        .prevbtn, .nextbtn{
            top: 65%;
        }
    }
}
@media (max-width: 400px){
    .authenticate{
        .authenticate-list{
            .block-wrapper{
                margin-top: 0;
                margin-bottom: 15px;
                p{
                    font-size: 16px;
                }
            }
        }
    }
}