.information{
    width: 90%;
    position: relative;
    .footer-branch{
        position: absolute;
        width: 30.6%;
        bottom: 0%;
        right: -11%;
    }
    .information-wrapper{
        img{
            max-height: 1000px;
        }
    }
    .content{
        position: relative;
        width: 38%;
        padding: 32px 40px 32px 40px;
        border-radius: 16px 0px 0px 0px;
        margin: auto 0;
        color: #000;
        font-family: Roboto;
        h2{
            font-size: 48px;            
        }
        p{
            color: #6E6E6E;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 60px;
        }
        label{
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
        }
        .button-wrapper{
            margin-top: 60px;
        }
        .button-details{
            background-color: var(--green);
            color: var(--white);
            border-radius: 79px;
            font-weight: 500;
            font-size: 18px;
            padding: 15px 35px;
        }
    }
}
@media (max-width: 1440px){
    footer{
        .information{
            width: 100%;
            .footer-branch{
                width: 22.1%;
                bottom: 11%;
                right: 0%;
            }
            .information-wrapper{
                img{
                    width: 65%;
                }
                .content{
                    width: 100%;
                }
            }
            
        }
    }
}
@media (max-width: 1024px){
    footer{
        .information{
            width: 100%;
            .information-wrapper{
                img{
                    width: 65%;
                }
                .content{
                    h2{
                        font-size: 40px;
                    }
                    p, label{
                        font-size: 17px;
                    }
                    .button-details{
                        padding: 12px 28px;
                        font-size: 17px;
                    }
                }
            }
            
        }
    }
}
@media (max-width: 768px){
    footer{
        .information{
            width: 100%;
            .footer-branch{
                display: none;
            }
            .information-wrapper{
                img{
                    width: 65%;
                }
                .content{
                    h2{
                        font-size: 32px;
                    }
                    p, label{
                        font-size: 15px;
                    }
                    .button-details{
                        padding: 10px 22px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
@media (max-width: 481px){
    footer{
        .information{
            width: 100%;
            .information-wrapper{
                flex-direction: column;
                img{
                    width: 100%;
                }
                .content{
                    width: 100%;
                    h2{
                        font-size: 35px;
                    }
                    p{
                        margin-bottom: 15px;
                        font-size: 17px;
                    }
                    label{
                        font-size: 17px;
                    }
                    .button-wrapper{
                        margin-top: 30px;
                    }
                    .button-details{
                        padding: 12px 28px;
                        font-size: 17px;
                    }
                }
            }
        }
    }
}
