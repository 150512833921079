.copy-right{
    background-color: var(--green);
    color: var(--white);
    height: 353px;
    position: relative;

    img{
        left: 50px;
        position: absolute;
        width: 22%;
    }
    .content{
        text-align: center;
        position: absolute;
        top: 45%;
        left: 36%;
        
        h3{
            font-size: 34px;
            font-weight: 900;
        }
        .social-media{
            margin-top: 30px;
            margin-bottom: 20px;
            column-gap: 30px
        }
        p{
            font-size: 18px;
        }
    }
}
@media(max-width: 1400px){
    .copy-right{
        .content{
            h3{
                font-size: 32px;
                font-weight: 800;
            }
        }
    }
}
@media (max-width: 1024px){
    .copy-right{
        height: 315px;
        
        .content{
            h3{
                font-size: 30px;
                font-weight: 700;
            }
            p{
                font-size: 16px;

            }
        }
    }
}
@media (max-width: 768px){
    .copy-right{
        height: 300px;
        img{
            width: 275px;
        }
        .content{
            h3{
                font-size: 28px;
            }
            p{
                font-size: 13px;

            }
            .social-media{
                margin: 20px 0px 15px 0px;
            }
        }
    }
}
@media (max-width: 481px){
    .copy-right{
        height: 300px;
        img{
            width: 210px;
            left: 26%;
        }
        .content{
            top: 50%;
            left: 4%;
            h3{
                font-size: 23px;
            }
            p{
                font-size: 13px;

            }
            .social-media{
                margin: 15px 0px 10px 0px;
                svg{
                    width: 25px;
                    height: 30px;
                }
            }
        }
    }
}