@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --black: #333;
    --white: #fff;
    --orange: #F2994A;
    --grey: #A0A79A;
    --green: #3F6745;

    --main-background: #F6F4F0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.5;
    text-rendering: optimizespeed;
    color: var(--black);
    background-color: var(--main-background);
}

button,
input,
[tabindex] {
    border: none;
    outline: none;
}

a[href] {
    text-decoration: none;
}
.container{
    max-width: 1625px;
    padding: 15px;
}